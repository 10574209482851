@import "../../css-config/mixins.scss";

.product {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url("../../Assets/Tech\ BGD.jpeg");
  background-position: fit-content center;
  background-repeat: no-repeat;
  background-size: cover;

  .header {

    h1 {
      font-size: 0.9rem;
      font-size: 600;
      color: rgb(255, 251, 251);


      @include md {
        font-size: 1.2rem;
        font-size: 600;

      }
    }

    p {
      font-size: 0.8rem;
      color: rgb(255, 255, 255);

      @include md {
        font-size: 0.9rem;
        color: rgba(245, 245, 245);
      }
    }
  }

  .content {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


    div {
      gap: 30px;
      padding: 8px;
    }

    img {
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      border: 2px solid rgba(245, 245, 245, 0.363);
      border-radius: 50%;
      color: white;
      background-color: rgba(245, 245, 245, 0.1);

      @include md {
        height: 80px;
        width: 80px;
      }
    }
  }

}