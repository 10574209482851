@import "../../css-config/mixins.scss";

.footer {
    width: 100%;
    background-color: rgb(26, 23, 23);
    color: white;

    .footer-content {
        padding: 50px 20px;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        @include md {
            justify-content: space-between;
            padding: 50px 0;
        }

        .col {
            max-width: 300px;

            .title {
                margin-bottom: 20px;
                font-size: 20px;
                font-weight: bolder;
                text-align: start;

            }

            .txt {
                text-align: start;
                color: rgba(245, 245, 245, 0.801);
                font-size: 0.8rem;
                @include md{
                font-size: 0.9rem;

                }
            }

            .c-item {
                display: flex;
                margin-bottom: 10px;
                a{
                    font-size: 0.9rem;
                    color: whitesmoke;
                    text-decoration: none;
                    cursor: pointer;
                }
                .contact{
                    font-size: 0.8rem;
                    @include md{
                        font-size: 0.9rem;
                    }
                }
                svg {
                    flex-shrink: 0;
                    font-size: 14px;
                    margin-right: 10px;
                    margin-top: 4px;
                    color: whitesmoke;
                }
            }

            span {
                &.text {
                    font-size: 0.8rem;
                    display: block;
                    text-align: start;
                    margin-bottom: 10px;
                    cursor: pointer;
                    @include md{
                        font-size: 0.9rem;
        
                        }
                }
            }
        }
    }
    .designer a{
        font-size: 10px;
        text-decoration: none;
        color: whitesmoke;
        cursor: pointer;
        @include md{
            font-size: 12px;
        }
    }

    .bottom-bar {
        border-top: 1px solid rgba(0, 0, 0, 0.1);

        .bottom-bar-content {
            padding: 20px;
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
            gap: 10px;

            @include md {
                padding: 0;
                height: 60px;
                max-width: 1200px;
                margin: 0 auto;
                flex-direction: row;
                justify-content: space-between;
                text-align: left;
            }

            .text {
                font-size: 11px;
                color: whitesmoke;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                @include md{
                    font-size: 12px;
                }
            }
        }
    }
}