@import "../../css-config/mixins.scss";

.main-header {
    width: 90.8%;
    padding: 0 20px;
    background: #ff0000;
    background: -webkit-linear-gradient(164deg, #ff0000 0%, #0cc6ad 50%, #0eb91c 100%);
    background: linear-gradient(164deg, #ff0000 0%, #0cc6ad 50%, #0eb91c 100%);
    color: black;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 99;
    -webkit-box-shadow: -12px 9px 29px -7.5px #000000;
    -moz-box-shadow: -12px 9px 29px -7.5px #000000;
    box-shadow: -12px 9px 29px -7.5px #000000;

    @include md {
        width: 94%;
        padding: 0 40px;
    }

    .header-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        max-width: 1200px;
        margin: 0 auto;

        @include md {
            height: 80px;
        }

        .left {
            list-style-type: none;
            display: flex;
            font-size: 0.3rem;
            gap: 25px;

            @include md {
                display: flex;
            }

            li {
                font-size: 11px;
                font-weight: 700;
                text-transform: uppercase;
                cursor: pointer;

                @include md {
                    font-size: 14px;
                }
            }
        }

        .right {
            display: flex;
            align-items: center;
            gap: 20px;

            @include md {
                gap: 24px;
            }

            svg {
                font-size: 20px;
                cursor: pointer;

                @include md {
                    font-size: 24px;
                }
            }

            .cart-icon {
                position: relative;

                span {
                    min-width: 20px;
                    text-align: center;
                    background-color: #8e2de2;
                    padding: 2.5px;
                    position: absolute;
                    top: -5px;
                    right: -12px;
                    font-size: 12px;
                    line-height: 1;
                    border-radius: 10px;
                }
            }

            .logo {
                height: 60px;
                width: 60px;

                @include md {
                    height: 80px;
                    width: 80px;
                }
            }
        }
    }

    &.sticky-header {
        position: sticky;
        top: 0;
        animation: sticky-header 0.3s ease forwards;
    }
}

@keyframes stickyHeader {
    0% {
        transform: translateY(-80px)
    }

    ;

    100% {
        transform: translateY(0);
    }

}