@import "../../css-config/mixins.scss";

.service {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: url("../../Assets/Background\ 2.jpeg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .previous {
        width: 88%;
        color: #ffffff;
        margin: 5px;
        display: flex;
        justify-content: start;

        #pre {
            text-decoration: none;
            display: flex;
            flex-direction: row;
            align-items: center;                                                                        
            border-radius: 10px;
            padding-left: 15px;
            padding-right: 25px;
            margin: 5px;
            cursor: pointer;
        }
        #pre:hover{
            background-color: #ffffff20;
        }

        p {
            font-size: 1.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        span {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 5px;
        }

    }

    .header {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 30px;

        h1 {
            font-size: 1.5rem;
            font-weight: bold;
            margin: 0px;
            color: #ffffff;

            @include md {
                font-size: 2.2rem;
            }
        }

        .underline {
            height: 4px;
            width: 200px;
            background-color: rgb(248, 248, 252);
            border-radius: 8px;
            margin: 10px;
        }

        h1:hover+.underline {
            width: 240px;
            cursor: pointer;
        }
    }

    .content {
        height: fit-content;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 50px;
        margin: 10px;


        .context {
            height: fit-content;
            width: 250px;
            margin: 10px;
            color: #ffffff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            padding: 20px;


            .image {
                margin: 10px;
                height: 140px;
                width: 140px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                border-radius: 50%;
                border: 3px solid whitesmoke;
                cursor: pointer;

                @include md {
                    height: 170px;
                    width: 170px;
                }

                img {
                    height: 140px;
                    width: 190px;
                    border-radius: 50%;

                    @include md {
                        height: 170px;
                        width: 220px;
                    }
                }

                img:hover {
                    height: 150px;
                    width: 200px;

                    @include md {
                        height: 180px;
                        width: 230px;
                    }
                }
            }

            h1 {
                width: 100%;
                font-size: 1.2rem;
                font-weight: bold;
                margin: 10px;


                @include md {
                    width: 100%;
                    font-size: 1.5rem;
                    margin: 10px;
                }
            }

            p {
                width: 100%;
                margin: 0;
                font-size: 0.7rem;
                color: rgba(255, 255, 255, 0.8);

                @include md {
                    font-size: 0.8rem;
                }

                span {
                    color: #ffffff;
                    font-weight: 600;
                }
            }

            .pods {
                font-size: 0.7rem;
            }
        }

        .context:hover {
            background-color: rgba(250, 251, 253, 0.07);
            border: 2px solid rgba(250, 251, 253, 0.07);

        }
    }

}

.laptop {
    background-image: url("../../Assets/Background\ 3.jpeg");

}

.watch {
    background-image: url("../../Assets/Background\ 4.jpeg");

}

.headphone {
    background-image: url("../../Assets/Background\ 5.jpeg");

}

.pods {
    background-image: url("../../Assets/Background\ 6.jpeg");

}