@import "../../css-config/mixins.scss";


.container1 {
    height: 100vh;
    width: 100%;
    gap: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #353972;
    background: -webkit-linear-gradient(46deg, #353972 0%, #030438 100%);
    background: linear-gradient(46deg, #353972 0%, #030438 100%);

    @include md {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: fit-content;
        gap: 0;

    }


    .content {
        height: fit-content;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        align-items: center;
        gap: 60px;

        @include md {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 100px;
        }

        .banner1 {
            width: 80%;
            line-height: 27px;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            text-align: start;

            @include md {
                width: 40%;
            }

            h1 {
                color: #fcf9f9;
                font-size: 1.6rem;
                padding: 8px;
                overflow: hidden;
                /* Ensures the content is not revealed until the animation */
                border-right: .15em solid orange;
                /* The typwriter cursor */
                white-space: nowrap;
                /* Keeps the content on a single line */
                margin: 0 auto;
                /* Gives that scrolling effect as the typing happens */
                letter-spacing: .15em;
                /* Adjust as needed */
                animation:
                    typing .9s steps(60, end),
                    blink-caret .75s step-end infinite;

                @include md {
                    font-size: 3.6rem;
                    padding: 22px;

                }


                /* The typing effect */
                @keyframes typing {
                    from {
                        width: 0
                    }

                    to {
                        width: 100%
                    }
                }

                /* The typewriter cursor effect */
                @keyframes blink-caret {

                    from,
                    to {
                        border-color: transparent
                    }

                    50% {
                        border-color: rgb(158, 157, 157);
                    }
                }
            }

            p {
                width: 100%;
                font-size: 0.9rem;
                font-weight: 600;
                color: #a8a3a3;
                text-align: center;

                @include md {
                    font-size: 1.6rem;
                    line-height: 40px;

                }
            }
        }

        .banner2 {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                height: 300px;
                width: 300px;
                margin-top: 10px;

                @include md {
                    height: 520px;
                    width: 520px;
                }
            }
        }
    }

    .connect {
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        @include md {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

        }

        h1 {
            width: 120%;
            margin: 0;
            padding: 0;
            font-size: 1.4rem;
            color: rgba(245, 245, 245, 0.705);

            @include md {
                width: 28%;
            }
        }

        @include md {
            width: 80%;
        }

        .card {
            width: 100%;
            height: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 25px 25px;
            gap: 40px;

            @include md {
                width: fit-content;
                gap: 40px;

            }

        }

        /* for all social containers*/
        .socialContainer {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            border: 1px solid black;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            transition-duration: .3s;

            @include md {
                width: 62px;
                height: 62px;
            }
        }

        /* instagram*/
        .containerOne:hover {
            background-color: #d62976;
            transition-duration: .3s;
        }

        /* linkdin*/
        .containerThree:hover {
            background-color: #0072b1;
            transition-duration: .3s;
        }

        /* Whatsapp*/
        .containerFour:hover {
            background-color: #128C7E;
            transition-duration: .3s;
        }

        .socialContainer:active {
            transform: scale(0.9);
            transition-duration: .3s;
        }

        .socialSvg {
            width: 17px;
        }

        .socialSvg path {
            fill: rgb(255, 255, 255);
        }

        .socialContainer:hover .socialSvg {
            animation: slide-in-top 0.3s both;
        }

        @keyframes slide-in-top {
            0% {
                transform: translateY(-50px);
                opacity: 0;
            }

            100% {
                transform: translateY(0);
                opacity: 1;
            }
        }


    }
}